exports.lista  = [
    {path: 'administracao', icon: 'business-colour.svg', name: 'Administração', bg: ''},
    {path: 'analise-e-desenvolvimento-de-sistemas', icon: 'computer-science-colour.svg', name: 'Análise e Desenvolvimento de Sistemas', bg: ''},
    {path: 'arquitetura-e-design', icon: 'architecture-colour.svg', name: 'Arquitetura e Design', bg: 'https://emergemag.com.br/wp-content/uploads/2020/01/solano-benitez-arquitetura-3.jpg'},
    {path: 'arquitetura-e-urbanismo', icon: 'architecture-colour.svg', name: 'Arquitetura e Urbanismo', bg: ''},
    {path: 'banco-de-dados', icon: 'computer-science-colour.svg', name: 'Banco de Dados', bg: ''},
    {path: 'biomedicina', icon: 'biological-sciences-colour.svg', name: 'Biomedicina', bg: ''},
    {path: 'ciencia-da-computacao', icon: 'computer-science-colour.svg', name: 'Ciência da Computação', bg: ''},
    {path: 'ciencias-biologicas', icon: 'biological-sciences-colour.svg', name: 'Ciências Biológicas', bg: ''},
    {path: 'ciencias-contabeis', icon: 'economics-colour.svg', name: 'Ciências Contábeis', bg: ''},
    {path: 'ciencias-economicas', icon: 'economics-colour.svg', name: 'Ciências Econômicas', bg: ''},
    {path: 'comercio-exterior', icon: 'law-colour.svg', name: 'Comércio Exterior', bg: ''},
    {path: 'comunicacao', icon: 'languages_linguistics-colour.svg', name: 'Comunicação', bg: ''},
    {path: 'comunicacao-institucional', icon: 'theology_religion-colour.svg', name: 'Comunicação Institucional', bg: ''},
    {path: 'design', icon: 'design-colour.svg', name: 'Design', bg: ''},
    {path: 'design-grafico', icon: 'design-colour.svg', name: 'Design Gráfico', bg: ''},
    {path: 'direito', icon: 'law-colour.svg', name: 'Direito', bg: ''},
    {path: 'educacao', icon: 'education-colour.svg', name: 'Educação', bg: ''},
    {path: 'educacao-fisica', icon: 'politics_international-relations-colour.svg', name: 'Educação Física', bg: ''},
    {path: 'enfermagem', icon: 'medicine-colour.svg', name: 'Enfermagem', bg: ''},
    {path: 'engenharia-civil', icon: 'business-colour.svg', name: 'Engenharia Civil', bg: ''},
    {path: 'engenharia-de-producao', icon: 'personal-development-colour.svg', name: 'Engenharia de Produção', bg: ''},
    {path: 'engenharia-e-mecatronica', icon: 'technology_engineering-colour.svg', name: 'Engenharia e Mecatrônica', bg: ''},
    {path: 'engenharia-eletrica', icon: 'physical-sciences-colour.svg', name: 'Engenharia Elétrica', bg: ''},
    {path: 'engenharia-mecanica', icon: 'business-colour.svg', name: 'Engenharia Mecânica', bg: ''},
    {path: 'estetica-e-cosmetica', icon: 'business-colour.svg', name: 'Estética e Cosmética', bg: ''},
    {path: 'farmacia', icon: 'medicine-colour.svg', name: 'Farmácia', bg: ''},
    {path: 'fisioterapia', icon: 'business-colour.svg', name: 'Fisioterapia', bg: ''},
    {path: 'fonoaudiologia', icon: 'business-colour.svg', name: 'Fonoaudiologia', bg: ''},
    {path: 'fotografia', icon: 'art-colour.svg', name: 'Fotografia', bg: ''},
    {path: 'gastronomia', icon: 'business-colour.svg', name: 'Gastronomia', bg: ''},
    {path: 'gerenciais', icon: 'personal-development-colour.svg', name: 'Gerenciais', bg: ''},
    {path: 'gestao-comercial', icon: 'personal-development-colour.svg', name: 'Gestão Comercial', bg: ''},
    {path: 'gestao-da-qualidade', icon: 'personal-development-colour.svg', name: 'Gestão da Qualidade', bg: ''},
    {path: 'gestao-da-tecnologia-da-informacao', icon: 'personal-development-colour.svg', name: 'Gestão da Tecnologia da Informação', bg: ''},
    {path: 'gestao-de-recursos-humanos', icon: 'personal-development-colour.svg', name: 'Gestão de Recursos Humanos', bg: ''},
    {path: 'gestao-financeira', icon: 'business-colour.svg', name: 'Gestão Financeira', bg: ''},
    {path: 'gestao-hospitalar', icon: 'education-colour.svg', name: 'Gestão Hospitalar', bg: ''},
    {path: 'informatica', icon: 'business-colour.svg', name: 'Informática', bg: ''},
    {path: 'jogos-digitais', icon: 'business-colour.svg', name: 'Jogos Digitais', bg: ''},
    {path: 'jornalismo', icon: 'business-colour.svg', name: 'Jornalismo', bg: ''},
    {path: 'licenciatura-em-educacao-especial', icon: 'business-colour.svg', name: 'Licenciatura em Educação Especial', bg: ''},
    {path: 'logistica', icon: 'business-colour.svg', name: 'Logística', bg: ''},
    {path: 'marketing', icon: 'business-colour.svg', name: 'Marketing', bg: ''},
    {path: 'mecatronica-industrial', icon: 'business-colour.svg', name: 'Mecatrônica Industrial', bg: ''},
    {path: 'medicina', icon: 'medicine-colour.svg', name: 'Medicina', bg: ''},
    {path: 'medicina-veterinaria', icon: 'medicine-colour.svg', name: 'Medicina Veterinária', bg: ''},
    {path: 'nutricao', icon: 'business-colour.svg', name: 'Nutrição', bg: ''},
    {path: 'odontologia', icon: 'medicine-colour.svg', name: 'Odontologia', bg: ''},
    {path: 'pedagogia', icon: 'business-colour.svg', name: 'Pedagogia', bg: ''},
    {path: 'processos-gerenciais', icon: 'business-colour.svg', name: 'Processos Gerenciais', bg: ''},
    {path: 'producao-audiovisual', icon: 'business-colour.svg', name: 'Produção Audiovisual', bg: ''},
    {path: 'psicologia', icon: 'psychology-colour.svg', name: 'Psicologia', bg: ''},
    {path: 'publicidade-e-propaganda', icon: 'business-colour.svg', name: 'Publicidade e Propaganda', bg: ''},
    {path: 'radio-e-tv', icon: 'business-colour.svg', name: 'Rádio e TV', bg: ''},
    {path: 'radiologia', icon: 'business-colour.svg', name: 'Radiologia', bg: ''},
    {path: 'redes-de-computadores', icon: 'business-colour.svg', name: 'Redes de Computadores', bg: ''},
    {path: 'seguranca-da-informacao', icon: 'business-colour.svg', name: 'Segurança da Informação', bg: ''},
    {path: 'servico-social', icon: 'politics_international-relations-colour.svg', name: 'Serviço Social', bg: ''},
    {path: 'sistemas-de-informacao', icon: 'business-colour.svg', name: 'Sistemas de Informação', bg: ''},
    {path: 'turismo', icon: 'business-colour.svg', name: 'Turismo', bg: ''},
]
// }
