<template>
    <div class="container">
        <div class="row justify-content-center">
            <h1>Top Livros Lidos</h1>
            <div class="col-8">

                <div class="row">
                    <b-form-select @change="mudaCursoOnStage(selected)" v-model="selected" :options="options"></b-form-select>
                </div>

                <div v-if="curso_on_stage.titulo" class="container">
                    <div class="row">
                        <div class="col-1">
                            <img v-if="curso_on_stage.titulo" class="categoria-img" :src="'https://www.perlego.com/images/subject_icons/' + curso_on_stage.icone" style="height: 50px">
                            <div v-else style="height: 50px"></div>
                        </div>
                        <div class="col" style="align-self: end;">
                            <h5>{{curso_on_stage.titulo}}</h5>
                        </div>
                    </div>
            
                    <!-- Área de Teste
                    <div class="row">
                        <div class="col">
                            <ol>
                                <h6>Array original do Firebase</h6>
                                <li v-for="tag in curso_on_stage.livros_top_lidos" :key="tag.id">{{tag.mms_id}}</li>
                            </ol>
                        </div>
                        <div class="col">
                            <ol>
                                <h6>Adicionados</h6>
                                {{livrosAdicionados}}
                            </ol>
                            <ol>
                                <h6>Removidos</h6>
                                {{livrosExcluidos}}
                            </ol>
                        </div>
                    </div> -->

                    <hr>

                    <div class="row">
                        <b-form inline class="my-3">
                            <b-form-input :state="validation" v-model="novo_top_lido_mms_id" placeholder="Escreva o mms_id para adicionar ao Top Lidos" type="text"></b-form-input>
                            <b-form-invalid-feedback :state="validation">Your user ID must be 5-12 characters long.</b-form-invalid-feedback>
                            <b-button v-on:click="adicionaLivroNovaLista()" variant="primary" >Adicionar</b-button>
                            <b-button v-on:click="atualizaResultados()" variant="secondary" >Restaurar</b-button>
                        </b-form>
                    </div>

                    <hr>

                    <b-list-group>
                        <b-list-group-item v-for="livro in novos_livros_top_lidos" :key="livro.id" class="flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h6 class="mb-1"><strong>{{livro.mms_id}}</strong></h6>
                                <small>{{livro.base_dados}}</small>
                            </div>
                            <p class="mb-1" style="text-align: left">{{livro.titulo}}</p>
                            <b-button variant="link" v-on:click="removeLivroTopLidosOnStage(livro.mms_id)"> (Remover)</b-button>
                        </b-list-group-item>
                    </b-list-group>

                    <hr>

                    <b-button v-on:click="salvaAlteracoesTopLidos()" variant="primary" >Salvar alterações</b-button>
                </div>
                <div v-else class="container">
                    <h5>Escolha um curso para começar</h5>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import tags_lista from '@/store/categorias.js'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: {
    },
    data() {
        return {
            lista_uninove: tags_lista.lista,
            selected: "",
            curso_on_stage: {
                titulo: '',
                icone: '',
                livros_top_lidos: []
            },
            novos_livros_top_lidos: [],
            novo_top_lido_mms_id: '',
            options: [], //{ value: null, text: 'Please select an option' }
            titulo_selecionado: '',
            icone_selecionado: '',
            key_atualizacao: 0,
            livros_excluidos: [],
            livros_adicionados: []
        }
    },
    computed: {
        antigo_livros_mms_id(){
            return this.curso_on_stage.livros_top_lidos.map(x => x.mms_id)
        },

        novos_livros_mms_id(){
            return this.novos_livros_top_lidos.map(x => x.mms_id)
        },

        livrosExcluidos(){
            return this.antigo_livros_mms_id.filter(i => !this.novos_livros_mms_id.includes(i));
        },

        livrosAdicionados(){
            return this.novos_livros_mms_id.filter(i => !this.antigo_livros_mms_id.includes(i));
        },

        // validation() {
        //     return !this.novos_livros_mms_id.includes(this.novo_top_lido_mms_id)
        // }
    },
    watch: {
    },
    methods: {
        retornaTopLidos: function(curso){
            console.log(curso, typeof curso)
            let respostaRaw = [];
            db.collection("acervo-completo")
            .where("top_lidos", "array-contains", curso)
            .orderBy("index")
            .get()
            .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                    respostaRaw.push(doc.data())
                });
                this.curso_on_stage.livros_top_lidos = respostaRaw
                console.log(this.curso_on_stage.livros_top_lidos)
                // Atualiza o elemento. Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie o valor
                this.key_atualizacao++
                // Add para o array atual que pode ser editado e onde vamos trabalhar
                this.novos_livros_top_lidos = [];
                respostaRaw.forEach(x => this.novos_livros_top_lidos.push(x))
                return respostaRaw
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os Top Lidos solicitados para o curso: ", curso, ". Erro: ", error);
            });
        },

        adicionaLivroNovaLista: function(mms_id){
            db.collection("acervo-completo").doc(this.novo_top_lido_mms_id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    console.log(doc.data())
                    this.novos_livros_top_lidos.push(doc.data())
                    this.novo_top_lido_mms_id = ''
                    if(!this.livros_adicionados.includes(doc.data().mms_id)){
                        this.livros_adicionados.push(doc.data().mms_id)
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log("O documento socilicitado pelo mms_id: ", mms_id, " não existe");
                }
            })
            .catch((error) => {
                console.log("Não foi possivel retornar o livro solicitados para o mms_id: ", mms_id, ". Erro: ", error);
            });
        },

        mudaCursoOnStage(curso_selecionado) {
            this.curso_on_stage.titulo = this.lista_uninove.find(x => x.path === curso_selecionado).name
            this.curso_on_stage.icone = this.lista_uninove.find(x => x.path === curso_selecionado).icon
            console.log('curso selecionado', curso_selecionado)
            console.log('curso filtrado', this.lista_uninove.find(x => x.path === curso_selecionado))
            console.log('curso filtrado.path', this.lista_uninove.find(x => x.path === curso_selecionado).path)
            this.curso_on_stage.livros_top_lidos = this.retornaTopLidos(this.lista_uninove.find(x => x.path === curso_selecionado).path)
            console.log('curso on stage array', this.curso_on_stage.livros_top_lidos)
        },

        removeLivroTopLidosOnStage(codigo){
            this.novos_livros_top_lidos = this.novos_livros_top_lidos.filter(x => x.mms_id !== codigo)
            // Adiciona a lista dos excluidos
            if(!this.livros_excluidos.includes(codigo)){
                this.livros_excluidos.push(codigo)
            }
        },

        salvaAlteracoesTopLidos() {
            let curso_alvo = this.selected
            let livros_para_adicionar_top_lidos = this.livrosAdicionados
            let livros_para_excluir_top_lidos = this.livrosExcluidos
            console.log('Vamos ADICIONAR aos top lidos de ',curso_alvo,' os livros: ', livros_para_adicionar_top_lidos)
            console.log('Vamos REMOVER dos top lidos de ',curso_alvo,' os livros: ', livros_para_excluir_top_lidos)

            // Adicionando ao top_lidos
            livros_para_adicionar_top_lidos.forEach(livro_codigo =>
                this.adicionaLivroTopLidos(livro_codigo, curso_alvo)
            )

            // Escluindo do top_lidos
            livros_para_excluir_top_lidos.forEach(livro_codigo =>
                this.excluiLivroTopLidos(livro_codigo, curso_alvo)
            )

        },

        adicionaLivroTopLidos(mms_id_livro, curso_alvo){
            db.collection("acervo-completo").doc(mms_id_livro.toString())
            .get()
            .then((doc) => {
                let novo_array = doc.data().livros_top_lidos
                if (doc.data().livros_top_lidos){
                    novo_array.push(curso_alvo)
                } else {
                    novo_array = []
                    novo_array.push(curso_alvo)
                }
                

                db.collection("acervo-completo").doc(mms_id_livro.toString())
                .update({
                    "top_lidos": novo_array
                })
                .then(() => {
                    console.log('ADICIONADO ', mms_id_livro, ' ao top_lidos de: ', curso_alvo,)
                    this.atualizaResultados()
                    
                })
                .catch((error) => {
                    console.error(error);
                });
            }).catch((error) => {console.error(error);});
        },

        excluiLivroTopLidos(mms_id_livro, curso_alvo){
            db.collection("acervo-completo").doc(mms_id_livro.toString())
            .get()
            .then((doc) => {
                console.log( doc.data())
                let novo_array = doc.data().top_lidos
                
                if (doc.data().top_lidos){
                    novo_array = novo_array.filter(x => x != curso_alvo)
                    console.log('novo array: ', novo_array)

                    db.collection("acervo-completo").doc(mms_id_livro.toString())
                    .update({
                        "top_lidos": novo_array
                    })
                    .then(() => {
                        console.log('REMOVIDO ', mms_id_livro, ' dos top_lidos de: ', curso_alvo,)
                        this.atualizaResultados()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } else {
                    console.log('Erro, não há top_lidos neste livro!!!')
                }
                
            }).catch((error) => {console.error(error);});
        },

        atualizaResultados(){
            this.retornaTopLidos(this.selected)
        }
        
    },
    mounted(){
        this.options = this.lista_uninove.map((x) =>{
            return {value: x.path, text: x.name}
        })
    },
}

</script>

<style scoped>
.categoria-img{
    height: 3rem;
    width: 3rem;
}
</style>


